html {
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
}

/* https://github.com/facebook/create-react-app/issues/11773 */
body > iframe {
  pointer-events: none;
}

#jsd-widget {
  pointer-events: unset;
  top: 3px !important;
  right: 175px !important;
  z-index: 1101 !important;
}

.hideWidget {
  visibility: hidden;
}

a,
a:hover,
a:visited,
a:active {
  text-decoration: none;
  color: unset;
}

#root {
  height: 100%;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: #fff;
}

/* TODO: ZA: CSS: Check whether the issue with additional spacing is fixed in newer versions */
.mui-fixed {
  padding-right: 0 !important;
}

/* this line will disable link on mapbox logo */
.mapboxgl-ctrl-logo {
  pointer-events: none !important;
}

/* this line will disable bottom-right links on mapbox */
.mapboxgl-ctrl-attrib-inner {
  pointer-events: none !important;
}

.smcx-modal {
  top: initial !important;
  left: initial !important;
  right: 10px !important;
  bottom: 10px !important;
}

@media print {
  html {
    height: 100%;
  }

  body {
    height: 100%;
    -webkit-print-color-adjust: exact;
  }

  @page {
    margin: 0;
    size: A4 landscape;
  }

  /* Hide all Whatfix elements from print */
  [id*='wfx'] {
    visibility: hidden !important;
  }

  #jsd-widget {
    display: none;
  }

  .smcx-modal {
    display: none !important;
  }

  #js-plotly-tester {
    display: none;
  }
}
